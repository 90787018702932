import { Locales } from '../../interfaces';

const enUS: Locales = {
  countries: {
    AQ: 'Antarctica',
    AR: 'Argentina',
    BB: 'Barbados',
    BE: 'Belgium',
    BO: 'Bolivia',
    BR: 'Brazil',
    CA: 'Canada',
    IC: 'Canary Islands',
    CL: 'Chile',
    CO: 'Colombia',
    CZ: 'Czech Republic',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    SV: 'El Salvador',
    DE: 'Germany',
    HN: 'Honduras',
    IN: 'India',
    ID: 'Indonesia',
    MX: 'Mexico',
    NL: 'Netherlands',
    NG: 'Nigeria',
    PA: 'Panama',
    PY: 'Paraguay',
    PE: 'Peru',
    PH: 'Philippines',
    ZA: 'South Africa',
    KR: 'South Korea',
    TZ: 'Tanzania',
    TH: 'Thailand',
  },
  select: {
    country: 'Select a country',
    vendor: 'Select a vendor',
  },
  routes: {
    credit: 'Credit',
    upload: 'Upload file',
    progress: 'File progress',
    pocDetails: 'POC details',
    files: 'POCs files',
    views: 'Custom view',
  },
  pages: {
    credit: {
      title: 'Credit management',
      summary: {
        total: 'Total POCs',
        pending: 'Pending approvals',
        inprogress: 'In progress',
        active: 'Active credit',
        denied: 'Credit denied',
      },
    },
    files: {
      title: 'POCs files',
    },
    upload: {
      title: 'Upload file',
      uploader: {
        title: 'File upload',
        description: `
          The existing POC information will be updated according to the uploaded file. New POC added to the file will not be included in the POC list.

          \nThe upload may take some time to process. Once the upload is complete, the user can leave the page and return later by clicking on the file on the POC Files page.
        `,
        fileUploader: {
          label: 'Drag or browse your file',
          description: 'Drag and drop file here to upload. The file format must be XLSX.',
        },
        fileDescription: {
          title: 'File description',
          subTitle: '(optional)',
          description: 'Make this file easier to identify by providing some details.',
        },
      },
      buttons: {
        back: 'Go back',
        upload: 'Upload File',
        apply: 'Update POCs',
        goToCredit: 'Go to credit',
        goToFiles: 'Go to files',
        toasts: {
          analysis: '{ validated } POCs were successfully updated.',
          processing: 'Processing file. { fileName }',
          success: 'File successfully uploaded! { fileName }',
          warning: 'File uploaded with errors. { fileName }',
          error: 'Upload error. { fileName }',
        },
      },
      progress: {
        title: 'Analysis of uploaded file',
        processingTitle: 'Updating credit conditions and status',
        meter: {
          starting: 'Starting',
          progress: '{ progressValue } out of { progressMaxValue } POCs processed.',
          description: 'Analysis time may be longer when the file contains many POCs.',
          descriptionProgressing:
            'Processing time may be longer when there are many POCs to be updated.',
          error: 'The file contains no valid POCs. Review the file and upload it again.',
          slow: 'The upload is taking longer than usual. Wait a moment.',
        },
        finished: {
          pocs: {
            success: 'POCs ready to be added',
            error: 'POCs with error',
            tooltip:
              "It's only possible to increase the credit limit to 200% of the suggested amount, and the maximum term allowed is 30 days.",
          },
          alert:
            'Download the error list and upload a fixed new file in the previous step, or proceed with only the POCs ready to be added.',
          download: 'Download error list',
        },
        error: {
          analysis: 'Unable to complete the file analysis. Upload the file again.',
          processing: 'Unable to complete the update. Upload the file again.',
        },
      },
    },
    'poc-details': {
      recommendation: {
        title: 'Credit line recommendation',
        status: {
          pending: 'Pending',
          denied: 'Denied',
          waitingActivation: 'Waiting activation',
          waitingOnboarding: 'Waiting onboarding',
        },
        labels: {
          creditLimit: 'Credit limit',
          termFee: 'Term/fee',
          recommendation: 'Recommendation',
          updatedOn: 'Updated on',
          approvedBy: 'Edited by',
          approvalMethod: 'Edit method',
        },
        timeline: {
          title: 'Recommendation timeline',
        },
      },
      information: {
        title: 'POC information',
        labels: {
          pocName: 'Name',
          pocDocument: 'ID',
          pocAddress: 'Address',
          pocEmail: 'Email',
          pocPhoneNumber: 'Phone number',
          pocAccount: 'Account ID',
        },
      },
      conditions: {
        title: 'Current credit line conditions',
        status: {
          active: 'Active',
          blocked: 'Blocked',
          overdue: 'Overdue',
          due: 'Due',
        },
        labels: {
          creditLimit: 'Credit limit',
          limitAvailable: 'Limit available',
          balance: 'Balance',
          overdue: 'Overdue',
          termFee: 'Term/fee',
          paymentMethod: 'Payment method',
          activatedOn: 'Activated on',
          approvedBy: 'Approved by',
          approvalMethod: 'Approval method',
          fileName: 'File name',
          fileDescription: 'File description',
        },
      },
      history: {
        credit: {
          title: 'Credit history',
          description: "All status changes made to the POC's credit conditions.",
          buttons: { trigger: 'Show credit history' },
          information: {
            resume: '{ limit } - { term } - { fee }',
            by: 'By { identifier }',
          },
          labels: {
            byAndHour: 'by { identifier } at { hour }',
            creditLimit: 'Credit limit:',
            termFee: 'Term/fee:',
            method: {
              edit: 'Edit method:',
              approval: 'Approval method:',
              denial: 'Denial method:',
            },
          },
          status: {
            newCreditRecommendation: 'New credit recommendation',
            approvedRecommendation: 'Credit recommendation approved',
            editedSuggestion: 'Credit suggestion edited',
            creditConditionsEdited: 'Current credit conditions edited',
            recommendationDenied: 'Credit recommendation denied',
            creditLineBlocked: 'Credit line blocked by nonpayment',
            creditLineUnblocked: 'Credit line unblocked',
            creditLineActivated: 'Credit line activated',
          },
        },
      },
    },
    views: {
      title: 'Custom view',
      description:
        'Create customized visualizations in the table to suit your analysis requirements.',
      buttons: { back: 'Go back', save: 'Save and apply' },
      toasts: {
        warning: 'You can add a maximum of 6 columns to a table.',
        success: '{ name } view has been saved and applied to the table.',
      },
      inputs: {
        name: {
          label: 'View name',
          placeholder: 'Enter name',
        },
        type: {
          label: 'View type',
          options: {
            RECOMMENDED: {
              label: 'Credit recommendations',
              tooltip: 'POCs marked by the algorithm as good candidates for credit.',
            },
            NOT_RECOMMENDED: {
              label: 'POCs without credit recommendation',
              tooltip: 'POCs not marked by the algorithm as good candidates for credit.',
            },
          },
        },
      },
      columns: {
        select: { description: 'Select up to 6 columns to display in the table.' },
        order: {
          description: 'Sort the selected columns based in the order of your preference.',
        },
      },
    },
  },
  formatting: {
    datetime: { 'long-time': `{ date } at { time }` },
    input: {
      text: {
        percentage: '{ value } %',
        days: '{value, plural, =1 {# day} other {# days}}',
      },
      suffix: {
        percentage: '%',
        days: '{value, plural, =1 {day} other {days}}',
      },
    },
    recommendation: {
      NEW_CREDIT_OFFER: 'Credit offer',
      INCREASE: 'Credit increase',
      DECREASE: 'Credit decrease',
    },
    'approval-method': {
      FILE_BATCH_UPDATE: 'By file',
      SYSTEM: 'By system',
    },
    'payment-method': {
      CASH: 'Cash',
      CREDIT: 'Credit',
      BANK_SLIP: 'Bank slip',
      BANK_SLIP_INSTALLMENT: 'Bank slip installment',
    },
    'term-fee': '{term, plural, =1 {# day} other {# days}} / {fee}',
  },
  tables: {
    loading: 'Loading items...',
    empty: 'There are no clients in this list yet.',
    search: 'Search by POC name or ID',
    pagination: {
      quantity: 'of',
      itensPerPage: 'Items per page: { numberPage }',
    },
    selectable: {
      selected: { singular: 'client selected', plural: 'clients selected' },
      deselect: 'Deselect all',
    },
    views: {
      selector: { label: 'View: { name }' },
      card: {
        custom: { title: 'Custom views' },
        alert: 'You have reached the limit of 10 custom views. Delete a view to create a new one.',
        standard: {
          title: 'Standard views',
          DEFAULT: 'Standard',
          RECOMMENDED: 'Credit recommendations',
          NOT_RECOMMENDED: 'POCs without credit recommendation',
        },
        buttons: { create: 'Create new custom view' },
        tooltips: { edit: 'Edit view', delete: 'Delete view' },
        modal: {
          title: 'Delete custom view?',
          description: 'This action cannot be undone.',
          buttons: { cancel: 'Não, voltar', delete: 'Yes, delete' },
          toasts: { success: 'Your custom view has been deleted.' },
        },
      },
    },
    filters: {
      empty: 'No filter results.',
      buttons: {
        filter: '{value, plural, =0 {Filter} one {Filter (1)} other {Filters (#)}}',
        cancel: 'Cancel',
        apply: 'Apply',
        clearAll: 'Clear all',
        clearDate: 'Clear date',
      },
      chips: {
        min: 'Minimum { min }',
        max: 'Maximum { max }',
        startDate: 'Start date { min }',
        endDate: 'End date { max }',
        to: '{ min } to { max }',
      },
      inputs: {
        updatedAt: {
          name: 'Last update',
          min: { label: 'Start date', placeholder: 'Insert the date' },
          max: { label: 'End date', placeholder: 'Insert the date' },
        },
        creditLimit: {
          name: 'Credit limit',
          min: { label: 'Minimum limit', placeholder: 'Enter the value' },
          max: { label: 'Maximum limit', placeholder: 'Enter the value' },
        },
        fee: {
          name: 'Fee',
          min: { label: 'Minimum fee', placeholder: '0' },
          max: { label: 'Maximum fee', placeholder: '0' },
        },
        term: {
          name: 'Term',
          min: {
            label: 'Minimum term',
            placeholder: '0',
            sufix: '{value, plural, =1 {day} other {days}}',
          },
          max: {
            label: 'Maximum term',
            placeholder: '0',
            sufix: '{value, plural, =1 {day} other {days}}',
          },
        },
        status: {
          name: 'Status',
          title: 'Status',
          labels: {
            processing: 'Processing',
            integration: 'Onboarding',
            activated: 'Active',
            denied: 'Denied',
          },
        },
        recommendation: {
          name: 'Recommendation',
          title: 'Recommendation',
          labels: {
            creditOffer: 'Credit offer',
            creditIncrease: 'Credit increase',
            creditDecrease: 'Credit decrease',
          },
        },
      },
    },
    files: {
      tab: { title: 'Arquivos' },
      empty: 'There are no files in this list yet',
      search: 'Search by File name',
      status: {
        pending: 'Pending',
        uploaded: 'Uploaded',
        analyzing: 'Analyzing',
        analyzed: 'Analyzed',
        processing: 'Processing',
        success: 'Done',
        errors: 'Uploaded with errors',
      },
      columns: {
        name: 'File name',
        totalPOCs: 'POCs updated',
        description: 'File description',
        uploadedBy: 'Updated by',
        uploadedAt: 'Updated on',
        status: 'Status',
        actions: 'Action',
      },
    },
    pending: {
      tab: { title: 'Pending' },
      columns: {
        pocName: 'POC',
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        term: 'Term',
        fee: 'Fee',
        score: 'Score',
        recommendation: 'Recommendation',
        updatedAt: 'Last update',
        actions: 'Action',
        salesAgency: 'Sales agency',
        salesArea: 'Sales area',
        customerType: 'Customer type',
      },
    },
    inprogress: {
      tab: { title: 'In progress' },
      status: {
        processing: 'Processing',
        integration: 'Onboarding',
      },
      columns: {
        pocName: 'POC',
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        term: 'Term',
        fee: 'Fee',
        updatedAt: 'Last update',
        updatedBy: 'Updated by',
        status: 'Status',
      },
    },
    completed: {
      tab: { title: 'Completed' },
      status: {
        active: 'Active',
        denied: 'Denied',
      },
      columns: {
        pocName: 'POC',
        accountId: 'Account ID',
        creditLimit: 'Credit limit',
        term: 'Term',
        fee: 'Fee',
        updatedAt: 'Last update',
        updatedBy: 'Updated by',
        status: 'Credit status',
      },
    },
  },
  files: {
    buttons: {
      upload: 'Upload file',
      download: 'Download file',
    },
    modal: {
      title: 'Download file',
      description: 'Download files to approve or deny client credit lines. Files cannot contain:',
      itemsList: {
        first: 'Added or renamed columns',
        second: 'Added or renamed clients',
        third: 'Any information that has been added or modified except for approval status',
      },
      fileDetails: {
        title: 'File details',
        description: 'Select the information you want to include in the file.',
        subTitle: 'Client status:',
        subDescription: 'Select the statuses to include in the file.',
        checkboxes: {
          all: 'All',
          pending: 'Pending approval ',
          approved: 'Credit approved',
          denied: 'Credit denied',
        },
      },
      buttons: {
        back: 'Back',
        confirm: 'Generate and download file',
      },
      toasts: {
        info: 'File is generating and may take a few seconds. It will download automatically when it is finished.',
      },
    },
    errors: {
      412: {
        description:
          'Another file is already in process. You need to wait until its done before uploading a new file.',
      },
    },
  },
  editRecommendation: {
    title: 'Edit credit recommendation',
    description:
      'The recommendations are based on POC data analysis. When editing a credit recommendation, you must follow some conditions.',
    buttons: {
      cancel: 'Cancel',
      apply: 'Apply changes',
      tooltip: 'Edit status',
      edit: { recommendation: 'Edit recommendation', conditions: 'Edit conditions' },
    },
    toasts: { success: '{ value } credit conditions were changed.' },
    inputs: {
      creditLimit: {
        label: 'Credit limit',
        hint: 'Suggested: { value }.',
      },
      term: {
        label: 'Credit term',
        hint: 'Suggested: {value, plural, =1 {# day} other {# days}}.',
      },
      fee: {
        label: 'Credit fee',
      },
    },
  },
  approveRecommendation: {
    title: 'Approve credit recommendation?',
    description: {
      single: 'Select the option for this POC.',
      multiple: 'Select an option for all the selected POCs.',
    },
    counter: '{ counter } POCs selected',
    info: {
      title: 'Request POC onboarding',
      description:
        'Phone number, POC ID, proof of address and the acceptance of terms and conditions will be requested.',
    },
    radios: { request: 'Request', doNotRequest: "Don't request" },
    buttons: { back: 'No, go back', approve: 'Yes, approve' },
    warning: "If you don't request the onboarding, you will be responsible for this decision.",
  },
  offerCredit: {
    title: 'Offer credit?',
    tooltip: 'Offer credit.',
    warning:
      'Based on the information available, there is no credit recommendation for this POC. Any credit decisions made will be undertaken at your own discretion.',
    info: {
      title: 'Request POC onboarding',
      description:
        'Phone number, POC ID, proof of address, and an acceptance of terms and conditions will be requested.',
    },
    radios: { request: 'Request', doNotRequest: "Don't request" },
    buttons: {
      cancel: 'Go back',
      apply: 'Offer credit',
    },

    toasts: { success: 'Credit offered to { value }.' },
    inputs: {
      creditLimit: {
        label: 'Credit limit',
        placeholder: 'Enter a value',
      },
      term: {
        label: 'Credit term',
        placeholder: 'days',
      },
      fee: {
        label: 'Credit fee',
      },
    },
  },
  loading: {
    default: 'We are taking you to the screen',
    optimizely: 'Preparing your experience',
  },
  errors: {
    default: 'Something went wrong.',
    generic: 'Something went wrong. Try reload the page.',
    403: {
      title: 'Hmm... Access denied!',
      description: 'Unfortunately, you do not have permission to access this page.',
      button: 'Back',
    },
    404: {
      title: 'Hmm… Looks like this page is gone!',
      description: "It looks like this page wasn't found or doesn't exist.",
      button: 'Back',
    },
    500: {
      title: 'Ooops! Something went wrong',
    },
    503: {
      title: 'Ooops! Something went wrong',
      description: "We're having some trouble loading this page. Please, try again.",
      info: '',
      button: 'Try again',
    },
    custom: {
      buttons: { back: 'Go back', reload: 'Reload page' },
      'not-enabled': {
        both: {
          title: 'Country and vendor not enabled',
          description:
            'You do not have access to the selected country and vendor. Change your selection or request access.',
        },
        country: {
          title: 'Country not enabled',
          description:
            'You do not have access to the selected country. Change your selection or request access.',
        },
        vendor: {
          title: 'Vendor not enabled',
          description:
            'You do not have access to the selected vendor. Change your selection or request access.',
        },
      },
      'not-available': {
        title: 'Country not available',
        description:
          'The selected country is not yet available in this application. Select another.',
      },
      'something-went-wrong': {
        title: 'Something went wrong',
        description: 'We had trouble loading this page. Try reloading.',
      },
    },
  },
  source: {
    validations: {
      errors: {
        'error:term:zero': 'Must be {value, plural, =1 {# day} other {# days}} minimum.',
        'error:term:small': 'Must be {value, plural, =1 {# day} other {# days}} maximum..',
        'error:term:fee:big': 'Must be { value }% maximum.',
        'error:term:fee:negative': 'Must be equal to or greater than { value }%.',
        'error:limit:big': 'Maximum { value }% of the recommended amount.',
        'error:limit:small': 'Must be greater than or equal to { value }.',
      },
    },
  },
  approval: {
    buttons: {
      APPROVED: {
        single: 'Approve',
        multiple: 'Aprove selected',
        tooltip: 'Approve credit line',
      },
      DENIED: {
        single: 'Deny',
        multiple: 'Deny selected',
        tooltip: 'Deny credit line',
      },
    },
    toasts: {
      APPROVED: {
        single: '{ value } credit line is approved!',
        multiple: 'Credit line for { value } clients is approved!',
      },
      DENIED: {
        single: '{ value } credit line has been denied!',
        multiple: 'Credit line for { value } clients is denied!',
      },
    },
    errors: {
      412: {
        description:
          'An update is already in process. You need to wait until its done before making a new update.',
      },
    },
  },
  timeline: {
    approved: 'Approved',
    onboarding: 'Onboarding',
    activationProcess: 'Activation process',
    creditLineActivated: 'Credit line activated',
  },
};

export default enUS;
