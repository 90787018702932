import { useCallback, useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useGetTables } from '../../../../hooks/useGetTables';
import { useGetUserPreferences } from '../../../../hooks/useGetUserPreferences';
import { useRequester } from '../../../../hooks/useRequester';
import { AssessmentsItem, GenericResponse, Tables } from '../../../../interfaces';
import { getAssessments } from '../../../../services';
import SegmentService from '../../../../services/segment';
import { debounce } from '../../../../utils/debounce';
import { mappedAssessments } from '../../../../utils/mapper/mappedAssessments';
import { Table } from '../../../atoms/Table/Table';
import { SelectedRows } from '../../../atoms/Table/Table.types';
import { NoFilter } from '../../../icons/NoFilter/index.svg';
import { ApprovalButtons } from '../../../molecules/ApprovalButtons/ApprovalButtons';
import { useCredit } from '../../../pages/Credit/Context/CreditContext';
import { AssessmentsTableProps, CallbackRef } from './AssessmentsTable.types';

export const AssessmentsTable: React.FC<AssessmentsTableProps> = ({ type }) => {
  const { formatMessage } = useIntl();
  const { get } = useRequester();

  const navigate = useNavigate();

  const { filters, columns, tag, reload, recommended } = useGetTables(type);
  const { configs, selected: selectedHeader } = useGetUserPreferences();
  const { tables } = configs;

  const { hasApproval } = useCredit();

  const isFirstRender = useRef<boolean>(true);
  const callbackFn = useRef<CallbackRef>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [assessments, setAssessments] = useState<AssessmentsItem[]>([]);
  const [selected, setSelected] = useState<SelectedRows[]>([]);
  const [search, setSearch] = useState<string>('');
  const [filtered, setFiltered] = useState<string>('');
  const [pagination, setPagination] = useState({
    page: 1,
    size: 100,
    total: 0,
  });

  const requestController = useRef(new AbortController());

  const onFilter = async (filters: string) => {
    setPagination((prev) => ({ ...prev, page: 1 }));
    setFiltered(filters);

    await getData({ filters });
  };

  const onRow = (row: AssessmentsItem) => {
    navigate(
      `/bees-credit-management/poc-details/${row.assessmentId}?vendorId=${selectedHeader.vendorId}&country=${selectedHeader.country}`
    );
    SegmentService.paymentsButtonClicked(
      'Credit Line',
      'Credit Line Row',
      selectedHeader.vendorId,
      'Credit Management Access POC Details',
      `CREDIT_MANAGEMENT_${type.toUpperCase()}_TAB`,
      null
    );
  };

  const onPagination = (page: number, size?: number) =>
    setPagination((prev) => ({ ...prev, page, size: size ?? prev.size }));

  const onSelected = (rows: GenericResponse) => {
    if (Array.isArray(rows) && !rows.length) {
      setSelected([]);
      return;
    }

    if (Array.isArray(rows.data) && rows.data.length > 1) {
      setSelected(
        rows.data.map((row) => ({ id: row.id, values: row.values, checked: rows.checked }))
      );
      return;
    }

    if (!rows.checked) {
      if (Array.isArray(rows.data) && !rows.data.length) {
        setSelected([]);
        return;
      }

      setSelected((prev) => prev.filter((row) => row.id !== rows.rowId));
      return;
    }

    setSelected((prev) => [...prev, { id: rows.rowId, values: rows.data, checked: rows.checked }]);
  };

  const onCallback = useCallback(async () => {
    await getData({ filters: filtered || '' });

    setSelected([]);
    hasApproval.setter(!hasApproval.value);
  }, [filtered, recommended, pagination.size, pagination.page]);

  const toolbarExtra = useCallback(
    () => (
      <ApprovalButtons
        mode="multiple"
        type="button"
        button={{ size: 'small' }}
        selected={selected}
        callbackFn={onCallback}
      />
    ),
    [selected]
  );

  const getData = async ({ filters = '', search = '' }) => {
    setIsLoading(true);

    const response = await getAssessments({
      api: { get },
      params: {
        status: tables[type].status,
        search,
        filters,
        pagination,
        recommended,
      },
      config: {
        signal: requestController.current.signal,
      },
    });

    if (!response?.success) {
      setAssessments([]);
      setIsLoading(false);

      return;
    }

    const { data, pageable } = mappedAssessments(response.data);
    data.forEach((item: AssessmentsItem) => {
      item.actions = { selected: item, callbackFn: callbackFn.current, tag: tag };
    });

    setAssessments(data);
    setPagination((prev) => ({ ...prev, total: pageable.total }));
    setIsLoading(false);
  };

  const debouncedGetData = useCallback(debounce(getData, 700), [
    type,
    recommended,
    pagination.size,
    pagination.page,
  ]);

  const onSearch = async (search: string) => {
    requestController.current.abort();
    requestController.current = new AbortController();

    setSearch(search);
    setIsLoading(true);
    debouncedGetData({ search: search.trim() });
    setPagination((prev) => ({ ...prev, page: 1 }));
  };

  useEffect(() => {
    getData({ filters: filtered || '', search: search.trim() || '' });
  }, [pagination.page, pagination.size]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    getData({});
  }, [reload]);

  useEffect(() => {
    callbackFn.current = onCallback;
  }, [onCallback]);

  return (
    <Table
      data={assessments}
      columns={columns}
      loading={isLoading}
      tableHeight={`min(100vh - ${filtered ? '576px' : '520px'})`}
      onRow={onRow}
      filters={{ has: true, chips: true, config: filters, onFilter, onClear: onPagination }}
      pagination={{
        page: pagination.page,
        size: pagination.size,
        total: pagination.total,
        onChange: onPagination,
      }}
      search={{
        has: true,
        placeholder: formatMessage({ id: 'tables.search' }),
        value: search,
        onChange: onSearch,
      }}
      messages={{
        loading: formatMessage({ id: 'tables.loading' }),
        empty: {
          message: formatMessage({ id: `tables.${filtered ? 'filters.' : ''}empty` }),
          Icon: Boolean(filtered) && NoFilter,
        },
      }}
      {...(type === Tables.PENDING && {
        views: { has: true, table: type },
        selectable: { has: true, hasValue: selected.length > 0, onSelected },
        toolbarExtra: toolbarExtra,
      })}
    />
  );
};
